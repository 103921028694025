/**
 * Stores build information about <b>this specific version of the `ruffle-core` library</b>.
 *
 * It does not represent the version of Ruffle that may be in use by the page (for example, if a browser extension overrides it).
 *
 * @privateRemarks
 * This is generated at build time via `set_version.ts`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2025-01-13",
    versionChannel: "nightly",
    buildDate: "2025-01-13T00:07:02.745Z",
    commitHash: "b18472e9b4b228ce5b993ed61844f77868bc1646",
};
